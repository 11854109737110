import { Attr, HasMany } from "@temed/vue-jsonapi-orm";
import { ApiResource } from "@/models/ApiResource";
import {Dayjs} from "dayjs";
import {DayjsMeta} from "@/decorators/decorators";


export class Employee extends ApiResource {
    static jsonApiType = "employees";

    @Attr() firstName?: string;
    @Attr() lastName?: string;
    @Attr() middleName?: string;
    @Attr() userName?: string;
    @Attr() phone?: string;
    @Attr() email?: string;
    @Attr() telegramIds?: number[];

    @HasMany() comments?: Comment[];

    @DayjsMeta() createdDate?: Dayjs;
    @DayjsMeta() modifiedDate?: Dayjs;
}