import { Attr, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResource } from "@/models/ApiResource";
import { Employee } from "@/models/Employee";
import { Dayjs } from "dayjs";
import { DayjsMeta } from "@/decorators/decorators";

export class Comment extends ApiResource {
    static jsonApiType = "comments";

    @Attr() comment?: string;

    @BelongsTo() parent?: Comment;
    @BelongsTo() author?: Employee;
    @HasMany() replies?: Comment[];

    // meta (read-only)
    @DayjsMeta() createdDate?: Dayjs;
    @DayjsMeta() modifiedDate?: string;
}