import { Attr, HasMany, BelongsTo, Meta } from "@temed/vue-jsonapi-orm";
import { ApiResource } from "@/models/ApiResource";
import { Employee } from "@/models/Employee";
import { Dayjs } from "dayjs";
import { DayjsMeta } from "@/decorators/decorators";
import IIntegerValue from "@/dto/values/IIntegerValue";
import IStringValue from "@/dto/values/IStringValue";
import ITimeValue from "@/dto/values/ITimeValue";
import IMappedAnswer from "@/dto/IMappedAnswer";

export class UserAnswer extends ApiResource {
    static jsonApiType = "userAnswers";

    @DayjsMeta() createdDate?: string;
    @DayjsMeta() modifiedDate?: string;

    @Meta() valueName?: string;
    @Meta() stepId?: string;
    @Meta() step?: string;
    @Meta() stateCode?: string;
    @Meta() answer?: IIntegerValue | IStringValue | ITimeValue;
    @Meta() additional?: IMappedAnswer[];
    @Meta() comment?: string;
    @Meta() numberValue?: number;
    @Meta() answerType?: string;
}