import { Attr, Meta } from "@temed/vue-jsonapi-orm";
import { ApiResource } from "@/models/ApiResource";


export class User extends ApiResource {
    static jsonApiType = 'users';


    @Attr() firstName?: string
    @Attr() lastName?: string
    @Attr() userName?: string
    @Attr() name?: string
    @Attr() languageCode: string | undefined
    @Attr() email?: string

    // meta (read-only)
    @Meta() isPremium?: boolean;
    @Meta() isBot?: boolean;
    @Meta() mskTimeShift?: number;

    /**
     * Request active User
     */
    static async requestActiveUser(): Promise<User> {
        const builder = User.api()
            // .with(['roles', 'profileImage'])
        builder.path = User.apiPath
        const response = await builder.request('users')
        console.log("[ User::requestActiveUser ] builder:", builder)
        console.log("[ User::requestActiveUser ] response:", response)
        return User.resourceFromResponse(response.data).data
    }
}
