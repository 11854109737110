
import {Component, Vue, Prop} from 'vue-property-decorator';

import axiosInstance from "@/axios";
import dayjs, {Dayjs} from "dayjs";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomComponent,
  VisualMapComponent,
  MarkLineComponent

} from "echarts/components";
import VChart from "vue-echarts";
import IChartIntDataDto from "@/dto/IChartIntDataDto";
import ChartNumberDataItem from "@/dto/ChartNumberDataItem";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomComponent,
  VisualMapComponent,
  MarkLineComponent
]);

import { ResourceCollection } from "@temed/vue-jsonapi-orm";
import { BotScaleLog } from "@/models/BotScaleLog";
import ISorterItem from "@/dto/ISorterItem";

@Component({
  components: { VChart }
})
export default class ReportView extends Vue {
  private siteUrl = process.env.MAIN_SITE_UR;
  private pageSize = 10;
  private chartData: ChartNumberDataItem[] = [];

  private logHistory = new ResourceCollection(
      BotScaleLog.api()
          .perPage(10)
          .orderByDesc("answerDate")
  );
  private isLoading = false;
  private isLoadDone = false;
  private hasError = false;
  private errorCode: number | undefined;
  private errorMessage: string | undefined;

  private colors: string[] = [
      '#0E850E',
      '#26910E',
      '#429C0D',
      '#62A90C',
      '#88B50B',
      '#B2C10A',
      '#CEBA08',
      '#DB9E06',
      '#E87D05',
      '#F55702',
      '#FF2D04',
  ];

  private sorters: ISorterItem[] = [
    {name: 'По убыванию даты ответа', sort: '-answerDate'},
    {name: 'По возрастанию даты ответа', sort: 'answerDate'},
    {name: 'По убыванию уровня боли', sort: '-value,-answerDate'},
    {name: 'По возрастанию уровня боли', sort: 'value,-answerDate'}
  ]

  private currentSorter: ISorterItem = this.sorters[0];
  mounted() {
    this.loadData();
    this.logHistory.requestItems({ url: this.buildFirstPageUrl(this.pageSize, this.currentSorter.sort) })
  }

  get isDataReady(): boolean {
    return this.isLoadDone && this.chartData.length > 0;
  }

  get latestData(): string {
    if (!this.isDataReady) {
      return '-';
    }
    return this.chartData[this.chartData.length - 1]._date.format("DD.MM.YYYY");
  }

  get notNullChartData() {
    return this.chartData.filter(i => i.value != null);
  }

  get latest30DaysData() {
    if (this.notNullChartData.length < 30) {
      return this.notNullChartData;
    }
    return this.notNullChartData.slice(this.notNullChartData.length - 30, this.notNullChartData.length);
  }

  sortByValueAndDate = (data: ChartNumberDataItem[]) => {
    return data.sort((a, b) => {
      // Only sort on value if not identical
      if ((a.value ? a.value : 0) < (b.value ? b.value :0)) return -1;
      if ((a.value ? a.value : 0) > (b.value ? b.value :0)) return 1;
      // Sort on name
      if (a._date.isBefore(b._date)) return -1;
      if (a._date.isAfter(b._date)) return 1;
      // Both identical, return 0
      return 0;
    });
  }
  get minValueLatest30days() {
    if (this.latest30DaysData.length === 0) {
      return undefined;
    }
    const latestData = this.sortByValueAndDate([...this.latest30DaysData]);
    const minValue = latestData[0].value;
    const allMinValues = latestData.filter(i => i.value === minValue)
    return allMinValues[allMinValues.length-1]
  }
  get maxValueLatest30days() {
    if (this.latest30DaysData.length === 0) {
      return undefined;
    }
    const latestData = this.sortByValueAndDate([...this.latest30DaysData]);
    return latestData[latestData.length-1]
  }

  loadData() {
    const { key, code, id } = this.$route.params;
    this.isLoading = true;
    axiosInstance.get(`/api/reports/external/${key}:${code}:${id}/vas`)
        .then(response => {
          this.chartData = response.data.map((dto: IChartIntDataDto) => {
            return ChartNumberDataItem.of(dto)
          });
          this.isLoadDone = true;
          this.hasError = false;
        })
        .catch(error => {
          this.hasError = true;
          this.buildErrorData(error);
        })
        .finally(() => {
          this.isLoading = false;
        })

  }

  buildErrorData(error: any) {
    this.hasError = true;
    this.errorCode = error?.response?.status;
    if (this.errorCode === 400) {
      this.errorMessage = error?.response?.data.detail;
    }
    else {
      this.errorMessage = error?.response?.statusText;
    }
  }



  //* work with resource collection
  setCurrentSorter(newSorter: ISorterItem) {
    this.currentSorter = newSorter;
    this.logHistory.pagination = {
      ...this.logHistory.pagination,
      current_page: 1,
    }
    this.logHistory.requestItems({ url: this.buildFirstPageUrl(10, this.currentSorter.sort) })
  }

  buildLogHistoryUrl(page: number, size: number, sort: string) {
    const { key, code, id } = this.$route.params;
    return `/api/botScaleLogs/report/external/${key}:${code}:${id}?page[number]=${page}&page[size]=${size}&sort=${sort}`;
  }

  buildFirstPageUrl(size: number, sort: string) {
    return this.buildLogHistoryUrl(1, size, sort);
  }
  firstPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isFirstPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: 1,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      })
      this.scrollToHistoryHeader();
    }
    return;
  }
  lastPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isLastPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: this.logHistory.paginationMeta.totalPages,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      })
      this.scrollToHistoryHeader();
    }
    return;
  }
  nextPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isLastPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: this.logHistory.pagination.current_page + 1,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      });
      this.scrollToHistoryHeader();
    }
    return;
  }
  scrollToHistoryHeader() {
    this.$nextTick(() => {
      const el = document.getElementById('log-history-header');

      if (el) {
        window.scrollTo({
          top: this.getOffsetTop(el) - 16,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  }
  getOffsetTop = (element: any): number => {
    return element ? (element.offsetTop + this.getOffsetTop(element.offsetParent)) : 0;
  }

  prevPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isFirstPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: this.logHistory.pagination.current_page - 1,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      })
      this.scrollToHistoryHeader();
    }
    return;
  }

  parseAdditional(additional: string) {
    additional = additional.replace('1) ', '')
    additional = additional.replace('2) ', '#$@')
    additional = additional.replace('3) ', '#$@')
    return additional.split("#$@")
  }
  //*end work with resource collection

  get dataset(): any {
    return [
      [new Date("2022-10-18 08:58:00"), 4],
      [new Date("2022-10-20 17:01:00"), 4],
      [new Date("2022-10-22 17:01:00"), 3],
      [new Date("2022-10-24 17:01:00"), 3],
      [new Date("2022-10-26 17:15:00"), 4],
      [new Date("2022-10-28 14:00:00"), 3],
      [new Date("2022-10-30 16:50:00"), 3],
      [new Date("2022-11-01 17:00:00"), 3],
      [new Date("2022-11-03 17:01:00"), 3],
      [new Date("2022-11-05 17:00:00"), 2],
      [new Date("2022-11-07 17:00:00"), 1],
      [new Date("2022-11-09 17:00:00"), 3],
      [new Date("2022-11-11 17:30:00"), 3],
      [new Date("2022-11-13 17:20:00"), 4],
      [new Date("2022-11-15 17:00:00"), 8],
      [new Date("2022-11-16 17:00:00"), 9],
      [new Date("2022-11-17 17:01:00"), 7],
      [new Date("2022-11-18 17:01:00"), 5],
      [new Date("2022-11-19 17:42:00"), 3],
      [new Date("2022-11-20 17:42:00"), null],
      [new Date("2022-11-21 17:09:00"), 3],
      [new Date("2022-11-22 17:42:00"), null],
      [new Date("2022-11-23 17:25:00"), 3],
      [new Date("2022-11-24 17:42:00"), null],
      [new Date("2022-11-25 19:31:00"), 3],
      [new Date("2022-11-26 17:42:00"), null],
      [new Date("2022-11-27 17:19:00"), 6],
      [new Date("2022-11-28 17:42:00"), null],
      [new Date("2022-11-29 17:11:00"), 3],
      [new Date("2022-11-30 17:42:00"), null],
      [new Date("2022-12-01 17:00:00"), 4],
      [new Date("2022-12-03 18:43:00"), 3],
      [new Date("2022-12-05 17:00:00"), 3],
      [new Date("2022-12-07 17:35:00"), 3],
      [new Date("2022-12-09 17:00:00"), 3],
      [new Date("2022-12-11 18:15:00"), 3],
      [new Date("2022-12-13 17:00:00"), 4],
      [new Date("2022-12-15 17:00:00"), 2],
      [new Date("2022-12-17 21:32:00"), 2],
      [new Date("2022-12-20 17:00:00"), 3],
      [new Date("2022-12-22 17:00:00"), 3],
      [new Date("2022-12-24 17:00:00"), 2],
      [new Date("2022-12-26 20:46:00"), 2],
      [new Date("2022-12-29 03:22:00"), 2],
      [new Date("2022-12-31 17:00:00"), 1],
      [new Date("2023-01-02 17:00:00"), 1],
      [new Date("2023-01-04 17:42:00"), 2],
      [new Date("2023-01-07 10:51:00"), 2],
      [new Date("2023-01-09 17:01:00"), 2],
      [new Date("2023-01-11 17:06:00"), 1],
      [new Date("2023-01-13 17:00:00"), 2],
      [new Date("2023-01-15 17:00:00"), 2],
      [new Date("2023-01-17 17:14:00"), 2],
      [new Date("2023-01-19 17:00:00"), 2],
      [new Date("2023-01-21 19:14:00"), 3],
      [new Date("2023-01-23 17:00:00"), 2],
      [new Date("2023-01-25 17:11:00"), 2],
      [new Date("2023-01-27 17:01:00"), 3],
      [new Date("2023-01-29 17:00:00"), 0],
      [new Date("2023-01-31 18:01:00"), 1],
      [new Date("2023-02-02 17:00:00"), 2],
      [new Date("2023-02-04 17:02:00"), 2],
      [new Date("2023-02-06 17:00:00"), 2],
      [new Date("2023-02-08 18:01:00"), 2],
      [new Date("2023-02-10 18:09:00"), 2],
      [new Date("2023-02-12 18:00:00"), 2],
      [new Date("2023-02-14 18:26:00"), 2],
      [new Date("2023-02-18 17:00:00"), 2],
      [new Date("2023-02-20 18:52:00"), 0],
      [new Date("2023-02-22 21:18:00"), 1],
      [new Date("2023-02-25 18:22:00"), 2],
      [new Date("2023-02-27 18:09:00"), 1],
      [new Date("2023-03-01 18:38:00"), 2],
      [new Date("2023-03-04 17:00:00"), 2],
      [new Date("2023-03-05 17:00:00"), null],
      [new Date("2023-03-06 17:00:00"), null],
      [new Date("2023-03-07 17:08:00"), 2],
      [new Date("2023-04-07 17:08:00"), 2]
    ]
  }
  get buildChatOption():any {
    const { code } = this.$route.params;
    const reportDate = dayjs().format("DD.MM.YYYY, HHmmss")
    return {
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
            title: {
              zoom: 'Выбрать область',
              back: 'Сбросить зум'
            }
          },
          restore: {
            title: 'Восстановить исходное состояние'
          },
          saveAsImage: {
            name: `График ВАШ [${code}], ${reportDate}`,
            title: 'Сохранить график',
            excludeComponents: ['dataZoom', 'toolbox'],
            pixelRatio: 2
          }
        }
      },
      xAxis: {
        type: 'time',
        // boundaryGap: false,
        // scale: true,
        axisLabel: {
          rotate: 0,
          showMinLabel: true,
          showMaxLabel: true,
          formatter: function (value: Date) {
            const date = dayjs(value);
            return date.format('DD.MMM')
          },
          hideOverlap: true,
        },
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '30%'],
        max: 10,
        min: 0,
        name: 'Уровень боли по ВАШ',
        nameLocation: 'middle',
        nameGap: 40
      },
      tooltip: {
        trigger: 'axis',
        formatter: function(params: any) {
          const date = dayjs(params[0].data[0]);
          let output = `<div style="text-align: left">`;
          output += `<div style="text-align: right;">${date.format('dd, HH:mm, <b>DD.MM.YYYY</b>')}</div>`;
          // output += `<div  style="text-align: right; font-weight: bold;">${date.format('dddd')}</div>`;
          for (let i = 0; i < params.length; i++) {
            output += `<div style="white-space: nowrap;">${params[i].marker} ${params[i].seriesName}:&nbsp;<div style="display: inline-block; float: right; font-weight: 600;">${params[i].value[1]}</div></div>`;
          }
          output += `</div>`;
          return output
        }

      },
      dataZoom: [
        {
          start: this.notNullChartData.length < 30 ? 0 : 80
        },
        {
          type: 'inside'
        }
      ],
      visualMap: {
        show: false,
        top: 32,
        right: 70,
        type: 'continuous',
        seriesIndex: 0,
        min: 0,
        max: 10,
        text: ['10', '0'],
        inRange: {
          color: ['#0E850E', '#88B50B', '#CEBA08', '#E87D05', '#FF2D04'],
          symbolSize: [6, 18]
        },
        dimension: 1
      },
      series: [
        {
          name: 'ВАШ',
          type: 'line',
          smooth: 0.6,
          symbol: 'circle',
          symbolSize: 6,
          showAllSymbol: false,
          connectNulls: true,
          lineStyle: {
            // color: '#5470C6',
            width: 3
          },
          label: {
            // show: true,
            position: 'top'
          },
          markLine: {
            symbol: ['none', 'none'],
            silent: true,
            animation: false,
            lineStyle: {
              type: 'dashed',
              color: "#cccccc"
            },
            label: {
              show: true,
              position: 'insideEndTop',
              formatter: function (params: any) {
                const date = dayjs(params.value);
                return date.format('MMM, YYYY')
              }
            },
            data: [
                ...this.getMonthLineMarks(this.chartData),
            ]
          },
          areaStyle: {
            opacity: 0.5
          },
          // data: this.chartData.map(({date, value}) => { return [date.toDate(), value]})
          data: this.chartData.map(i => [i.date, i.value])
        }
      ]
    }
  }

  getMonthLineMarks = (data: ChartNumberDataItem[]) => {
    let month = -1;
    const lineMarks: any[] = [];
    data.forEach(item => {
      if (month !== item._date.month()) {
        month = item._date.month();
        lineMarks.push({ xAxis: item.date })
      }
    })
    if (lineMarks.length > 1) {
      return lineMarks.splice(1, lineMarks.length-1);
    }
    return lineMarks;
  }
  getMonthMarks2 = (data:any) => {
    let month = -1;
    let lines:any = [];
    data.forEach((val:any, index:number) => {
      if (month != val[0].getMonth()) {
        month = val[0].getMonth();
        lines.push({name: 'month', xAxis: index})
      }
    })
    return lines.splice(1, lines.length-2);
  }
}
