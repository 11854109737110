import Vue from 'vue'
import Vuex from 'vuex'
import { jsonApiVuexPlugin } from '@temed/vue-jsonapi-orm'
import { User } from "@/models/User";
import { Comment } from "@/models/Comment";
import { Employee } from "@/models/Employee";
import { BotScaleLog } from "@/models/BotScaleLog";
import { UserAnswer } from "@/models/UserAnswer";

const resources = [
    User, Comment, Employee,
    BotScaleLog, UserAnswer
]

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [ jsonApiVuexPlugin(resources) ]
})
