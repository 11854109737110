import dayjs from "dayjs";
import {ApiResourceBase} from "@temed/vue-jsonapi-orm";

export function DayjsMeta() {
    return (target: any, propertyKey: string) => {
        /**
         * Get original meta data.
         */
        const getter = function (this: ApiResourceBase) {
            return dayjs(this.$getMeta(propertyKey))
        }
        Object.defineProperty(target, propertyKey, {
            get: getter,
        })
    }
}