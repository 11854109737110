import axios, { AxiosRequestConfig } from "axios";
import Vue from "vue";

const axiosInstance = axios.create({
    // baseURL: 'http://localhost:8081',
    headers: {
        "Content-Type": "application/vnd.api+json"
    }
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    if (Vue.prototype.$keycloak.authenticated && config.headers) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
    }
    config.withCredentials = true;
    return config;
})

export default axiosInstance